import React, { useState, useEffect } from 'react';
import { CCard, CCardBody, CCardTitle, CCardText, CCardImage } from '@coreui/react';
import './Skills.css';

function Skills() {
    const [skillsData, setSkillsData] = useState([]);
  
    useEffect(() => {
      fetch('/api/skills/')
        .then((response) => response.json())
        .then((data) => {
            setSkillsData(data);
          })
          .catch((error) => {
            console.error('Error fetching skills:', error);
          });
    }, []);

    const getSkillLevelColor = (skillLevel) => {
        switch (skillLevel) {
        case 'Advanced':
            return 'green';
        case 'Intermediate':
            return 'orange';
        case 'Beginner':
            return 'red';
        default:
            return 'black'; // Default color if skill level is not recognized
        }
    };
  
    return (
      <div>
        <h1>Skills</h1>
        <div className="skill-container">
          {skillsData.map((skill) => (
            <CCard className="skill-card" style={{ width: '300px' }} key={skill.skill_name}>
              <CCardImage src={skill.skill_icon} alt={skill.skill_name} className="skill-image" />
              <CCardBody>
                <CCardTitle className="skill-card-title">{skill.skill_name}</CCardTitle>
                <CCardText className = "skill-card-text" style={{ color: getSkillLevelColor(skill.skill_level) }}>
                  {skill.skill_level}
                </CCardText>
              </CCardBody>
            </CCard>
          ))}
        </div>
      </div>
    );
  }
  
  export default Skills;