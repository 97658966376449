import React from 'react';
import './NavBar.css';
import { Link } from 'react-scroll';

function NavBar() {
  return (
    <header className="App-header">
      <nav>
        <Link activeClass="active" to="home" spy={true} smooth={true} offset={-70} duration={500}>
          Home
        </Link>
        <Link activeClass="active" to="skills" spy={true} smooth={true} offset={-70} duration={500}>
          Skills
        </Link>
        <Link activeClass="active" to="portfolio" spy={true} smooth={true} offset={-70} duration={500}>
          Portfolio
        </Link>
        <Link activeClass="active" to="contact" spy={true} smooth={true} offset={-70} duration={500}>
          Contact
        </Link>
      </nav>
    </header>
  );
}

export default NavBar;
