import React, { useState, useEffect } from 'react';
import { CButton, CForm, CFormInput, CFormLabel, CFormTextarea, CAlert, CContainer } from '@coreui/react';
import '@coreui/coreui/dist/css/coreui.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './ContactForm.css';
import axios from "axios";

const ContactForm = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [formStatus, setFormStatus] = useState(false);

  useEffect(() => {
   
  });
  
  const handleNameChange = (event) => {
    setName(event.target.value);
  }
  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  }

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const userData = {
      name: name,
      email: email,
      message: message
    };
    axios.post("https://mertnagas.com/api/send-mail", userData).then((response) => {
      console.log(response.status, response.data.token);
      if (response.status === 200) {
        document.getElementById("formResponseValid").style.display = 'initial';
        document.getElementById("submitButton").style.display = 'none';
        setFormStatus(true);
      }
      else {
        document.getElementById("formResponseInvalid").style.display = 'initial';
      }
    }).catch(function (error) {
      document.getElementById("formResponseInvalid").style.display = 'initial';
    })
  }

  return (
    <>
       <CContainer id='contactContainer'>
       <CContainer>
            <h1>Contact Me</h1>
            </CContainer>

          <CContainer id="formResponseValid" style={ {display: 'none', marginTop: '25px'} }>
            <CAlert color="success">Thank you for your message, we will get back to you as soon as possible.</CAlert>
          </CContainer>
          <CContainer id="formResponseInvalid" style={ {display: 'none',marginTop: '25px'} }>
            <CAlert color="danger">Unfortunately, your message could not be sent Please try again later.</CAlert>
          </CContainer>
      

        <CForm onSubmit={handleSubmit}>
            <CFormLabel className='contactFormLabel'>Name:</CFormLabel>
            <CFormInput disabled={formStatus} id = 'name' type = "name" placeholder='John Doe' onChange={handleNameChange} required></CFormInput>
            <CFormLabel className='contactFormLabel'>Email Address:</CFormLabel>
            <CFormInput disabled={formStatus} type="email" placeholder="johndoe@example.com" onChange={handleEmailChange} required/>
            <CFormLabel className='contactFormLabel'>Message: </CFormLabel>
            <CFormTextarea disabled={formStatus} type = "text" placeholder='Hi, can we work together...' onChange={handleMessageChange} required></CFormTextarea>
            <CButton id = "submitButton" type="submit" className="contactFormButton btn btn-primary">Submit</CButton>
        </CForm>
        </CContainer>
    </>
  );
};

export default ContactForm;