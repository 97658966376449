import React, { useState, useEffect } from 'react';
import { CCard, CCardBody, CCardTitle, CCardText, CCardImage, CCardLink } from '@coreui/react';
import './Portfolio.css';

function Portfolio() {
  const [portfolioData, setPortfolioData] = useState([]);

  useEffect(() => {
    fetch('/api/projects/')
      .then((response) => response.json())
      .then((data) => setPortfolioData(data));
  }, []);

  return (
    <div>
      <h1>Portfolio</h1>
      <div className="portfolio-container">
        {portfolioData.map((portfolio) => (
          <CCard className="portfolio-card" style={{ width: '300px' }} key={portfolio.project_name}>
            <CCardImage src={portfolio.project_icon} alt={portfolio.project_name} className="portfolio-image" />
            <CCardBody>
              <CCardTitle className="portfolio-card-title">{portfolio.project_name}</CCardTitle>
              <CCardText className="portfolio-card-text">{portfolio.project_summary}</CCardText>
              <CCardLink href={portfolio.project_url}>Take a look!</CCardLink>
            </CCardBody>
          </CCard>
        ))}
      </div>
    </div>
  );
}

export default Portfolio;
