import React from 'react';
import './App.css';
import { Element } from 'react-scroll';
import Typewriter from 'typewriter-effect';
import NavBar from './NavBar';
import Portfolio from './Portfolio';
import Skills from './Skills';
import ContactForm from './ContactForm';
import Footer from './Footer';

function App() {
  return (
    <div className="App">
      <NavBar />
      <main>
        <Element name="home" className="section" id="home">
          <h1>
            <Typewriter
              options={{
                strings: ['My name is Mert.', 'Welcome to my portfolio.', 'Lets bring your concept to reality.', 'Contact Me.'],
                autoStart: true,
                loop: true,
                delay: 50, // Delay between typing and deleting characters
                cursor: '|', // Custom cursor character for more natural rhythm
                typeSpeed: 80, // Typing speed
                deleteSpeed: 50, // Deleting speed
              }}
              style={{ width: 'auto', height: 'auto' }}
            />
          </h1>
        </Element>
        <Element name = "skills" className = "section" id = "skills">
          <Skills />
        </Element>
        <Element name = "portfolio" className = "section" id = "portfolio">
          <Portfolio />
        </Element>
        <Element name="contact" className="section" id="contact">
          <ContactForm />
        </Element>
        <Footer />
      </main>
    </div>
  );
}

export default App;
